import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Row, Col, FormGroup, Fade } from 'react-bootstrap';
import _ from 'lodash';
import { I18n } from 'react-redux-i18n';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import ReCAPTCHA from 'react-google-recaptcha';

import AllCountries from './AllCountries';
import './SignUpForm.css';
import FormikControl from '../../Constants/FormikControl';
import { createDriverApi } from '../../Constants/apiConfig';
import {
  showLoadingSpiner,
  hideLoadingSpiner,
} from '../../Actions/LoadingActions';
import { copyTextToClipboard } from '../../utils/commonFunctions';
import AppConfig from '../../App.config';

import ThankYou from '../ThankYou';
import Banner from '../Banner';

const axios = require('axios');

function SignUpForm(props) {
  const fleetInfo = props.fleetInfo.fleet || {};
  const [submited, setSubmited] = useState(false);
  const [fleetName, setFleetName] = useState('');
  const [isCustomizeHeading, setIsCustomizeHeading] = useState(false);
  const [heading, setHeading] = useState([]);
  const [headingSelected, setHeadingSelected] = useState('');
  const [token, setToken] = useState('');
  const [resData, setResData] = useState({});
  const reCaptcha = useRef();
  const [key, setKey] = useState(0);
  const [capitalLetter, setCapitalLetter] = useState(false);
  const [groupBrand, setGroupBrand] = useState({ enable: false });
  const [registrationType, setRegistrationType] = useState([]);
  const [useUserName, setUseUserName] = useState(false);
  const [bannerTop, setBannerTop] = useState([]);
  const [bannerBottom, setBannerBottom] = useState([]);
  const [usePassword, setUsePassword] = useState(false);

  const { companies, hideLoadingSpiner, showLoadingSpiner } = props;

  const fleetId = props.fleetId;
  const OSInfo = props.OSInfo;
  const referralID = props.referralID;
  useEffect(() => {
    if (props.fleetInfo.fleet) {
      setFleetName(props.fleetInfo.fleet.name.toUpperCase());
      if (props.fleetInfo.fleet.providerSetting.otherSetting) {
        setHeading(props.fleetInfo.fleet.providerSetting.otherSetting.heading);
        setIsCustomizeHeading(
          props.fleetInfo.fleet.providerSetting.otherSetting.customizeHeading
        );
      }
      if (props.fleetInfo.fleet.capitalLetter) {
        const isCapitalLetter = props.fleetInfo.fleet.capitalLetter.name
          .drvSignUp
          ? props.fleetInfo.fleet.capitalLetter.name.drvSignUp
          : false;
        setCapitalLetter(isCapitalLetter);
      }
      if (props.fleetInfo.fleet.syncPaxProfile) {
        const syncPaxProfile = props.fleetInfo.fleet.syncPaxProfile;
        setGroupBrand(syncPaxProfile);
      }
      if (
        props.fleetInfo.fleet.password &&
        props.fleetInfo.fleet.password.driver &&
        props.fleetInfo.fleet.password.regBy === 1
      ) {
        setUseUserName(true);
      }
      if (
        props.fleetInfo.fleet.password &&
        props.fleetInfo.fleet.password.driver
      ) {
        setUsePassword(true);
      }
    }
  }, [props.fleetInfo]);

  useEffect(() => {
    setKey(key + 1);
  }, [props.locale]);

  useEffect(() => {
    if (props.localeFleet && Array.isArray(heading)) {
      const result = heading.find((e) => e.language === props.localeFleet);
      if (result) {
        setHeadingSelected(result.text);
      }
    }
  }, [props.localeFleet]);

  useEffect(() => {
    const otherSetting =
      (fleetInfo.providerSetting && fleetInfo.providerSetting.otherSetting) ||
      {};
    if (otherSetting && otherSetting.bannerState1) {
      if (
        otherSetting.bannerState1.top &&
        otherSetting.bannerState1.top.length > 0
      ) {
        setBannerTop(otherSetting.bannerState1.top);
      }
      if (
        otherSetting.bannerState1.bottom &&
        otherSetting.bannerState1.bottom.length > 0
      ) {
        setBannerBottom(otherSetting.bannerState1.bottom);
      }
    }
  }, [fleetInfo]);

  const getCity = () => {
    let key;
    let value;
    let citys = [];
    _.forEach(props.fleetInfo.city, (e) => {
      if (e.isShowDriverSignUp) {
        if (e.transportation || e.assistant || e.delivery) {
          key = e.name;
          value = e._id;
          citys.push({ key, value });
        }
      }
    });
    return citys;
  };

  const getCompany = () => {
    let label;
    let value;
    let companies = [];
    _.forEach(props?.companies, (e) => {
      if (e.isActive) {
        label = e.name;
        value = e._id;
        companies.push({ label, value });
      }
    });
    return companies;
  };

  const getRegistrationType = (cityId) => {
    const cityInfo = _.filter(props.fleetInfo.city, { _id: cityId })[0];
    let services = [];
    if (props.fleetInfo.fleet) {
      const { providerSetting } = props.fleetInfo.fleet;
      if (providerSetting) {
        if (cityInfo.transportation) {
          if (providerSetting.driverIndividual.enable) {
            if (fleetId === 'gojosg') {
              services.push({
                key: I18n.t('home.INDIVIDUAL_CAR_GOJO'),
                value: '0',
              });
            } else {
              services.push({ key: I18n.t('home.INDIVIDUAL_CAR'), value: '0' });
            }
          }
          if (providerSetting.driverCompany.enable) {
            services.push({ key: I18n.t('home.COMPANY_CAR'), value: '1' });
          }
        }
        if (cityInfo.delivery) {
          if (
            providerSetting.deliveryDrvIndividual &&
            providerSetting.deliveryDrvIndividual.enable
          ) {
            services.push({
              key: I18n.t('home.DELIVERY_INDIVIDUAL_CAR'),
              value: '2',
            });
          }
          if (
            providerSetting.deliveryDrvCompany &&
            providerSetting.deliveryDrvCompany.enable
          ) {
            services.push({
              key: I18n.t('home.DELIVERY_COMPANY_CAR'),
              value: '3',
            });
          }
        }
      }
    }
    setRegistrationType(services);
    return services;
  };

  const getLinkterm =
    props.fleetInfo.fleet && props.fleetInfo.fleet.providerSetting
      ? props.fleetInfo.fleet.providerSetting.otherSetting.termsOfUse
      : '';
  const getLinkPrivacyPolicy =
    props.fleetInfo.fleet && props.fleetInfo.fleet.providerSetting
      ? props.fleetInfo.fleet.providerSetting.otherSetting.privacyPolicy
      : '';

  const countryCode =
    props.fleetInfo.fleet && props.fleetInfo.fleet.countryCode
      ? props.fleetInfo.fleet.countryCode.toUpperCase()
      : '';

  const getCountryCode = () => {
    let data = '';
    const countries = AllCountries.getCountries();
    data = _.filter(countries, (country) => {
      return country.iso2 === countryCode.toLowerCase();
    });
    return data;
  };
  const phoneInit = getCountryCode()[0]
    ? `+${getCountryCode()[0].dialCode}`
    : '';
  const initialValues = {
    fleetId: fleetId,
    preferredCity: '',
    service: 'transport',
    registrationType:
      registrationType.length && registrationType.length <= 1
        ? registrationType[0].value
        : '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    username: '',
    company: '',
    referralCode: referralID,
    OSInfo: OSInfo,
  };

  const phoneRegExp = /^[\+\d]?(?:[\d-.\s()]*)$/;
  const usernameRegExp = /^[a-zA-Z0-9]*$/;

  const validationSchema = Yup.object({
    preferredCity: Yup.string().required(
      I18n.t('validations.FIELD_IS_REQUIRED')
    ),
    service: Yup.string().required(I18n.t('validations.FIELD_IS_REQUIRED')),
    registrationType: Yup.string().required(
      I18n.t('validations.FIELD_IS_REQUIRED')
    ),
    firstName: Yup.string().required(I18n.t('validations.FIELD_IS_REQUIRED')),
    lastName: Yup.string().required(I18n.t('validations.FIELD_IS_REQUIRED')),
    email: Yup.string()
      .email(I18n.t('validations.INVALID_EMAIL')),
    phone: Yup.string()
      .min(8, I18n.t('validations.FIELD_IS_REQUIRED'))
      .matches(phoneRegExp, I18n.t('validations.INVALID_PHONE_NUMBER'))
      .required(I18n.t('validations.FIELD_IS_REQUIRED')),
    company: props.urlQueryHasCompany
      ? Yup.string()
          .required(I18n.t('validations.FIELD_IS_REQUIRED'))
      : Yup.string(),
    username: useUserName
      ? Yup.string()
          .min(3, I18n.t('validations.FIELD_IS_REQUIRED_BETWEEN_3_TO_20'))
          .max(20, I18n.t('validations.FIELD_IS_REQUIRED_BETWEEN_3_TO_20'))
          .matches(usernameRegExp, I18n.t('validations.INVALID_USERNAME'))
          .required(I18n.t('validations.FIELD_IS_REQUIRED'))
      : Yup.string(),
    referralCode: Yup.string(),
    OSInfo: Yup.string(),
  });

  const validate = (values) => {
    let errors = {};

    if (values.phone) {
      const phoneNumber = parsePhoneNumberFromString(values.phone, countryCode);
      if (!phoneNumber) {
        errors.phone = I18n.t('validations.INVALID_PHONE_NUMBER');
      }
      if (phoneNumber && !phoneNumber.isValid()) {
        errors.phone = I18n.t('validations.INVALID_PHONE_NUMBER');
      }
    }
    return errors;
  };

  const onSubmit = useCallback(
    (values, { setFieldError }) => {
      if (fleetId) {
        copyTextToClipboard(`f_${fleetId}`);
      }

      let params = values ? { ...values } : {};
      params = { ...params, token: token };

      if (!token) {
        return;
      }

      if (groupBrand.enable) {
        params = {
          ...params,
          groupId: groupBrand.groupId,
        };
      }
      if(values?.company) {
        params = {
          ...params,
          companyId: values.company,
        };
      }

      showLoadingSpiner();
      axios
        .post(createDriverApi, params)
        .then(function (response) {
          reCaptcha.current.reset();
          setToken('');
          hideLoadingSpiner();

          if (response.status === 200) {
            if (response.data.res.errorCode) {
              switch (response.data.res.errorCode) {
                case 3032:
                  setFieldError(
                    'username',
                    I18n.t('validations.USERNAME_EXITS')
                  );
                  break;
                case 3040:
                case 3034:
                  setFieldError(
                    'phone',
                    I18n.t('validations.PHONE_NUNBER_EXITS')
                  );
                  break;
                case 3037:
                  console.log('response.data.res ', response.data.res);
                  break;
                case 3039:
                  setFieldError(
                    'referralCode',
                    I18n.t('validations.REFERRAL_CODE_INVALID')
                  );
                  break;
                case 20008:
                  setFieldError(
                    'email',
                    I18n.t('validations.EMAIL_EXITS')
                  );
                  break;
                default:
                  break;
              }
            } else {
              setResData(response.data.res);
              setSubmited(true);
            }
          }
        })
        .catch((err) => {
          console.log('err ', err);
        });
    },
    [
      fleetId,
      groupBrand.groupId,
      groupBrand.enable,
      companies,
      showLoadingSpiner,
      hideLoadingSpiner,
      token,
    ]
  );

  let listLanguageRightToLeft = ['ur', 'ar'];
  let ctrRtl =
    listLanguageRightToLeft.indexOf(props.locale) >= 0 ? true : false;
  let classRtl = ctrRtl ? 'textRighToLeft' : '';



  return !submited ? (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => {
        // console.log("formik: ", formik);
        // console.log("formik.values: ", formik.values);
        // console.log("formik.touched: ", formik.touched);
        // console.log("formik.errors: ", formik.errors);
        // console.log("formik.isValid ", formik.isValid);
        return (
          <Form className="form-action">
            <div className="box">
              <div
                className={'box-form ' + classRtl}
                dir={ctrRtl ? 'rtl' : 'ltr'}
              >
                {props.desktop ? (
                  <div className="fleetname">
                    <p
                      style={{ color: `${props.mainColor}` }}
                      className="fleetname-content"
                    >
                      {I18n.t('home.BECOME', { name: fleetName })}
                    </p>
                    {isCustomizeHeading ? (
                      <p>{headingSelected}</p>
                    ) : (
                      <p>{I18n.t('home.HEADING')}</p>
                    )}
                  </div>
                ) : null}
                {bannerTop.length > 0 ? (
                  <Banner urlsBannerImage={bannerTop} position={'top'} />
                ) : null}
                <FormikControl
                  control="select"
                  label={I18n.t('home.PLACEHOVER_SELECT_PRE_CITY')}
                  name="preferredCity"
                  values={formik.values}
                  options={getCity()}
                  touched={formik.touched}
                  errors={formik.errors}
                  formik={formik}
                  getRegistrationType={getRegistrationType}
                />
                {formik.values.service === 'transport' ? (
                  registrationType.length <= 1 ? null : (
                    <FormikControl
                      control="select"
                      label={I18n.t('home.PLACEHOVER_SELECT_REGISTRATION_TYPE')}
                      name="registrationType"
                      values={formik.values}
                      options={registrationType}
                      touched={formik.touched}
                      errors={formik.errors}
                      formik={formik}
                    />
                  )
                ) : null}
                {
                  props.showSupplierSelection && (
                    <FormikControl
                      control="select"
                      label={I18n.t('home.PLACEHOVER_SELECT_COMPANY')}
                      name="company"
                      options={getCompany()}
                      valueDefault={props?.company?._id}
                      touched={formik.touched}
                      errors={formik.errors}
                      formik={formik}
                      placeholder={I18n.t('home.Company')}
                      hasSearch
                    />
                  )
                }
                {useUserName ? (
                  <FormikControl
                    control="inputUsername"
                    type="username"
                    label="Username"
                    name="username"
                    placeholder={I18n.t('home.USERNAME')}
                    touched={formik.touched}
                    errors={formik.errors}
                    formik={formik}
                  />
                ) : null}
                <Row>
                  <Col className="left-col">
                    <FormikControl
                      control="inputName"
                      type="text"
                      label="First Name"
                      name="firstName"
                      placeholder={I18n.t('home.FIRST_NAME')}
                      touched={formik.touched}
                      errors={formik.errors}
                      formik={formik}
                      capitalLetter={capitalLetter}
                    />
                  </Col>
                  <Col className="right-col">
                    <FormikControl
                      control="inputName"
                      type="text"
                      label="Last Name"
                      name="lastName"
                      placeholder={I18n.t('home.LAST_NAME')}
                      touched={formik.touched}
                      errors={formik.errors}
                      formik={formik}
                      capitalLetter={capitalLetter}
                    />
                  </Col>
                </Row>
                <FormikControl
                  control="input"
                  type="email"
                  label="Email"
                  name="email"
                  placeholder={I18n.t('home.EMAIL')}
                  touched={formik.touched}
                  errors={formik.errors}
                />
                <FormikControl
                  control="inputPhone"
                  type="text"
                  label="Mobile Number"
                  name="phone"
                  // placeholder={I18n.t("home.PHONE_NUMBER")}
                  placeholder={phoneInit}
                  touched={formik.touched}
                  errors={formik.errors}
                  formik={formik}
                  countryCode={countryCode}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Referral code (optional)"
                  name="referralCode"
                  placeholder={I18n.t('home.REFERRAL_CODE')}
                  touched={formik.touched}
                  errors={formik.errors}
                />
                <FormGroup>
                  <ReCAPTCHA
                    key={key}
                    ref={reCaptcha}
                    hl={props.locale.substring(0, 2)}
                    sitekey={AppConfig.GOOGLE.reCaptcha}
                    onChange={(token) => setToken(token)}
                    onExpired={(e) => setToken('')}
                  />
                </FormGroup>

                <FormikControl
                  control="policy"
                  label={I18n.t('home.I_AGREE_TO')}
                  name="policy"
                  linkTerm={
                    (props.isIframe && !props.fpc)
                      ? process.env.REACT_APP_PRIVACY_URL
                      : getLinkterm
                  }
                  target={(props.isIframe && !props.fpc) ? '_blank' : ''}
                  linkPrivacy={
                    (props.isIframe && !props.fpc)
                      ? process.env.REACT_APP_PRIVACY_URL
                      : getLinkPrivacyPolicy
                  }
                  fleetName={fleetName}
                />

                <button
                  type="submit"
                  className="register-btn button"
                  disabled={!formik.isValid || token === ''}
                  style={
                    !formik.isValid || token === ''
                      ? null
                      : { backgroundColor: `${props.mainColor}` }
                  }
                >
                  <span>{I18n.t('home.APPLY_NOW')}</span>
                </button>

                {bannerBottom.length > 0 ? (
                  <Banner urlsBannerImage={bannerBottom} position={'bottom'} />
                ) : null}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  ) : (
    <ThankYou
      isIframe={props.isIframe}
      fleetInfo={fleetInfo}
      mainColor={props.mainColor}
      resData={resData}
      appName={props.appName}
      OSInfo={props.OSInfo}
      fleetName={fleetName}
      fleetId={fleetId}
      isSubDomain={props.isSubDomain}
      referralID={referralID}
      usePassword={usePassword}
      ctrRtl={ctrRtl}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    fleet: state.fleet,
    locale: state.i18n.locale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoadingSpiner: () => dispatch(showLoadingSpiner()),
    hideLoadingSpiner: () => dispatch(hideLoadingSpiner()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
