export const id = {
  "name": "Bahasa indonesia",
  "flagCode": "id",
  "locale": "id-ID",
  "project": {
    "name": "Pendaftaran Pengemudi"
  },
  "key": "id",
  "validations": {
    "PLEASE_SELECT_CITY": "Pilih Kota.",
    "PLEASE_SELECT_SERVICE": "Please select service.",
    "PLEASE_SELECT_DRIVER_TYPE": "Pilih jenis driver.",
    "PLEASE_INPUT_FIRSTNAME": "Harap masukkan nama depan Anda.",
    "PLEASE_INPUT_LASTNAME": "Harap masukkan nama belakang Anda.",
    "PLEASE_INPUT_EMAIL": "Masukkan email Anda.",
    "INVALID_EMAIL": "Harap tentukan email yang valid.",
    "PLEASE_INPUT_MOBILE_PHONE": "Masukkan nomor telepon anda.",
    "INVALID_PHONE_NUMBER": "Harap tentukan nomor telepon yang valid.",
    "PHONE_NUNBER_EXITS": "Nomor telepon sudah digunakan dengan akun lain.",
    "USERNAME_EXITS": "Nama pengguna ini sudah ada di sistem kami.",
    "ERROR_INPUT_VALID_USERNAME": "Nama pengguna mungkin hanya berisi huruf & angka yang lebih rendah dan harus panjang antara 3 dan 20 karakter",
    "FIELD_IS_REQUIRED": "Bagian ini diperlukan.",
    "REFERRAL_CODE_INVALID": "Kode rujukan tidak valid.",
    "EMAIL_EXITS": "This email already exists. Please enter another one.",
    "INVALID_USERNAME": "Harap tentukan nama pengguna yang valid.",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "Nama pengguna harus antara 3 dan 20 karakter.",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "Ponsel ini sudah ada di sistem. Harap masukkan yang lain.",
    "4001": "The License Plate is exits",
    "NETWORK_ERROR": "Kesalahan: Kesalahan jaringan"
  },
  "general": {
    "CONTINUE": "Melanjutkan",
    "STEP_COMPLETE": "{0}/{1} Lengkap",
    "STEP_HEADER": "Langkah {0}",
    "BACK": "Kembali",
    "NEXT": "Lanjut",
    "SUBMIT": "Kirim",
    "Clear_Selected_Items": "Hapus item yang dipilih",
    "no_results": "Tidak ada hasil",
    "add_new_item": "Tambah baru"
  },
  "home": {
    "BECOME": "Become a %{name} Driver.",
    "HEADING": "Masukkan informasi dasar Anda untuk memulai",
    "APPLY_NOW": "Daftar",
    "INDIVIDUAL_CAR_GOJO": "Transportasi/Taksi - Saya memiliki kendaraan",
    "INDIVIDUAL_CAR": "Transportasi - Saya memiliki kendaraan",
    "COMPANY_CAR": "Transportasi - Kendaraan Perusahaan",
    "DELIVERY_INDIVIDUAL_CAR": "Pengiriman - Saya Memiliki Kendaraan",
    "DELIVERY_COMPANY_CAR": "Pengiriman - Kendaraan Perusahaan",
    "PLACEHOVER_SELECT_PRE_CITY": "Kota",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "Pilih jenis driver",
    "USERNAME": "Nama belakang",
    "FIRST_NAME": "Nama depan",
    "LAST_NAME": "Nama keluarga",
    "EMAIL": "Surel (opsional)",
    "PHONE_NUMBER": "Nomor handphone",
    "REFERRAL_CODE": "Kode rujukan (opsional)",
    "I_AGREE_TO": "Dengan mendaftar, saya telah membaca dan menyetujui",
    "TERMS_OF_USE": "Ketentuan Layanan",
    "AND": "dan",
    "PRIVACY_POLICY": "Kebijakan pribadi",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "Terima kasih telah mendaftar dengan %{name}!",
    "FINISH_3": "Masuk sekarang untuk memulai!",
    "FINISH_USE_PASSWORD_1": "Hampir sampai!",
    "FINISH_USE_PASSWORD_2": "Untuk mengaktifkan akun Anda dan menerima pekerjaan, silakan ikuti langkah -langkah ini:",
    "FINISH_USE_PASSWORD_3": "1. Masuk dengan nomor telepon terdaftar Anda",
    "FINISH_USE_PASSWORD_4": "2. Kata sandi: <span style = 'font-weight: bold'> 12345678 </span>",
    "FINISH_USE_PASSWORD_5": "3. Lengkapi Aplikasi Anda",
    "OK": "Gabung",
    "downloadApp": "Unduh <span style = 'color: %{color}'> %{name} </span> aplikasi driver sekarang dan login dengan nomor telepon terdaftar Anda."
  },
  "datePlaceHolder": "Mm/dd/yyyy"
};