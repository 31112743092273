export const zhHK = {
  "name": "Traditional Chinese",
  "flagCode": "tw",
  "locale": "zh-HK",
  "project": {
    "name": "司機註冊"
  },
  "key": "zhHK",
  "validations": {
    "PLEASE_SELECT_CITY": "請選擇城市。",
    "PLEASE_SELECT_SERVICE": "請選擇服務.",
    "PLEASE_SELECT_DRIVER_TYPE": "請選擇司機類型。",
    "PLEASE_INPUT_FIRSTNAME": "請輸入您的名字。",
    "PLEASE_INPUT_LASTNAME": "請輸入您的姓氏。",
    "PLEASE_INPUT_EMAIL": "請輸入您的電子郵件。",
    "INVALID_EMAIL": "請設定有效的電子郵件。",
    "PLEASE_INPUT_MOBILE_PHONE": "請輸入您的電話號碼。",
    "INVALID_PHONE_NUMBER": "請設定有效的電話號碼。",
    "PHONE_NUNBER_EXITS": "電話號碼已被其他帳戶使用。",
    "USERNAME_EXITS": "該用戶名已存在於我們的系統中。",
    "ERROR_INPUT_VALID_USERNAME": "用戶名只能包含小寫字母和數字，長度必須在 3 到 20 個字符之間",
    "FIELD_IS_REQUIRED": "此字段是必需的。",
    "REFERRAL_CODE_INVALID": "推薦代碼無效。",
    "EMAIL_EXITS": "This email already exists. Please enter another one.",
    "INVALID_USERNAME": "請設定有效的用戶名。",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "用戶名必須介於 3 到 20 個字符之間。",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "該電話已存在於系統中。 請使用其他號碼。",
    "4001": "此車牌已經存在",
    "NETWORK_ERROR": "錯誤：網絡錯誤"
  },
  "general": {
    "CONTINUE": "繼續",
    "STEP_COMPLETE": "{0}/{1} 已完成",
    "STEP_HEADER": "步驟{0}",
    "BACK": "後退",
    "NEXT": "下一個",
    "SUBMIT": "提交",
    "Clear_Selected_Items": "清除所選項目",
    "no_results": "沒有結果",
    "add_new_item": "添加"
  },
  "home": {
    "BECOME": "註冊成為 %{name} 司機。",
    "HEADING": "輸入您的基本信息即可開始",
    "APPLY_NOW": "註冊",
    "INDIVIDUAL_CAR_GOJO": "約車出行/出租車 - 我自己的車輛",
    "INDIVIDUAL_CAR": "約車出行 - 我擁有車輛",
    "COMPANY_CAR": "約車出行 - 公司車輛",
    "DELIVERY_INDIVIDUAL_CAR": "送貨-我自己的車輛",
    "DELIVERY_COMPANY_CAR": "送貨-公司車輛",
    "PLACEHOVER_SELECT_PRE_CITY": "城市",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "選擇司機類型",
    "USERNAME": "用戶名",
    "FIRST_NAME": "姓氏",
    "LAST_NAME": "名字",
    "EMAIL": "電子郵件 (可選)",
    "PHONE_NUMBER": "手機號碼",
    "REFERRAL_CODE": "推薦碼（可選）",
    "I_AGREE_TO": "註冊即表示我已閱讀並同意",
    "TERMS_OF_USE": "服務條款",
    "AND": "以及",
    "PRIVACY_POLICY": "隱私政策",
    "I_AGREE_TO_AFTER": ".",
    "Company": "供應商",
  },
  "finish": {
    "FINISH_1": "感謝您註冊 %{name}!",
    "FINISH_3": "立即登錄即可開始！",
    "FINISH_USE_PASSWORD_1": "差不多了！",
    "FINISH_USE_PASSWORD_2": "要激活您的帳戶並接收工作，請按照以下步驟操作：",
    "FINISH_USE_PASSWORD_3": "1. 使用您註冊的電話號碼登錄",
    "FINISH_USE_PASSWORD_4": "2.密碼：<span style='font-weight:bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. 完成您的申請",
    "OK": "登錄",
    "downloadApp": "立即下載<span style='color: %{color}' >%{name}</span> 司機應用程序並使用您註冊的電話號碼登錄。"
  },
  "datePlaceHolder": "MM/DD/YYYY"
};