export const lv = {
  "name": "Latvian",
  "flagCode": "lv",
  "locale": "lv-LV",
  "project": {
    "name": "Driver Signup"
  },
  "key": "lv",
  "validations": {
    "PLEASE_SELECT_CITY": "Lūdzu, izvēlieties pilsētu.",
    "PLEASE_SELECT_SERVICE": "Lūdzu, izvēlieties pakalpojumu",
    "PLEASE_SELECT_DRIVER_TYPE": "Lūdzu, izvēlieties autovadītāja veidu.",
    "PLEASE_INPUT_FIRSTNAME": "Lūdzu, ievadiet savu vārdu.",
    "PLEASE_INPUT_LASTNAME": "Lūdzu, ievadiet savu uzvārdu.",
    "PLEASE_INPUT_EMAIL": "Lūdzu, ievadiet savu e-pastu.",
    "INVALID_EMAIL": "Lūdzu, norādiet derīgu e-pastu.",
    "PLEASE_INPUT_MOBILE_PHONE": "Lūdzu, ievadiet savu tālruņa numuru.",
    "INVALID_PHONE_NUMBER": "Lūdzu, norādiet derīgu tālruņa numuru.",
    "PHONE_NUNBER_EXITS": "Tālruņa numurs jau tiek izmantots ar citu kontu.",
    "USERNAME_EXITS": "Šis lietotājvārds jau ir mūsu sistēmā.",
    "ERROR_INPUT_VALID_USERNAME": "Lietotājvārds var iekļaut tikai mazus burtus un ciparus, un tam jābūt no 3 līdz 20 rakstzīmēm garam.",
    "FIELD_IS_REQUIRED": "Šis lauks ir obligāts.",
    "REFERRAL_CODE_INVALID": "Ieteiukuma kods nav derīgs.",
    "EMAIL_EXITS": "This email already exists. Please enter another one.",
    "INVALID_USERNAME": "Lūdzu, norādiet derīgu lietotājvārdu.",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "Lietotājvārdam jābūt no 3 līdz 20 rakstzīmēm.",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "Šis tālrunis sistēmā jau ir reģistrēts. Lūdzu, ievadiet citu.",
    "4001": "Licences numurs ir reģistrēts.",
    "NETWORK_ERROR": "Kļūda: Tīkla kļūda"
  },
  "general": {
    "CONTINUE": "Turpināt",
    "STEP_COMPLETE": "{0}/{1} Pabeigt",
    "STEP_HEADER": "Solis {0}",
    "BACK": "Atpakaļ",
    "NEXT": "Nākamais",
    "SUBMIT": "Iesniegt",
    "Clear_Selected_Items": "Dzēst izvēlētas preces",
    "no_results": "Nav rezultātu",
    "add_new_item": "Pievienot jaunu"
  },
  "home": {
    "BECOME": "Kļūsti par %{name} autovadītāju.",
    "HEADING": "Ievadiet savu pamatinformāciju, lai sāktu.",
    "APPLY_NOW": "Reģistrēties",
    "INDIVIDUAL_CAR_GOJO": "Transports/Taksometrs - Man pieder transportlīdzeklis",
    "INDIVIDUAL_CAR": "Transports - Man pieder transportlīdzeklis",
    "COMPANY_CAR": "Transports - Uzņēmuma transportlīdzeklis",
    "DELIVERY_INDIVIDUAL_CAR": "Piegāde - Man pieder transportlīdzeklis",
    "DELIVERY_COMPANY_CAR": "Piegāde - Uzņēmuma transportlīdzeklis",
    "PLACEHOVER_SELECT_PRE_CITY": "Pilsēta",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "Izvēlieties autovadītāja veidu",
    "USERNAME": "Lietotājvārds",
    "FIRST_NAME": "Vārds",
    "LAST_NAME": "Uzvārds",
    "EMAIL": "E-pasts",
    "PHONE_NUMBER": "Mobilā tālruņa numurs",
    "REFERRAL_CODE": "Ieteikuma kods (nav obligāts)",
    "I_AGREE_TO": "Reģistrējoties esmu izlasījis un piekritis (-usi)",
    "TERMS_OF_USE": "Pakalpojuma sniegšanas noteikumiem",
    "AND": "un",
    "PRIVACY_POLICY": "Privātuma politikai",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "Paldies, ka reģistrējāties %{name}!",
    "FINISH_3": "Piesakieties tagad, lai sāktu!",
    "FINISH_USE_PASSWORD_1": "Gandrīz gatavs!",
    "FINISH_USE_PASSWORD_2": "Lai aktivizētu savu kontu un saņemtu darbu, lūdzu, veiciet šīs darbības:",
    "FINISH_USE_PASSWORD_3": "1. Piesakieties ar savu reģistrēto tālruņa numuru",
    "FINISH_USE_PASSWORD_4": "2. Parole: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. Aizpildiet savu pieteikumu",
    "OK": "Pieslēgties",
    "downloadApp": "Lejupielādējiet <span style='color: %{color}' >%{name}</span> Autovadītāju lietotni tagad un piesakieties ar savu reģistrēto tālruņa numuru."
  },
  "datePlaceHolder": "MM/DD/GGGG"
};