export const ta = {
  "name": "Tamil",
  "flagCode": "in",
  "locale": "ta-IN",
  "project": {
    "name": "இயக்கி பதிவு"
  },
  "key": "ta",
  "validations": {
    "PLEASE_SELECT_CITY": "நகரத்தைத் தேர்ந்தெடுக்கவும்.",
    "PLEASE_SELECT_SERVICE": "சேவையைத் தேர்ந்தெடுக்கவும்.",
    "PLEASE_SELECT_DRIVER_TYPE": "இயக்கி வகையைத் தேர்ந்தெடுக்கவும்.",
    "PLEASE_INPUT_FIRSTNAME": "உங்கள் முதல் பெயரை உள்ளிடவும்.",
    "PLEASE_INPUT_LASTNAME": "உங்கள் கடைசி பெயரை உள்ளிடவும்.",
    "PLEASE_INPUT_EMAIL": "தயவுசெய்து உங்கள் மின்னஞ்சலை உள்ளிடவும்.",
    "INVALID_EMAIL": "சரியான மின்னஞ்சலைக் குறிப்பிடவும்.",
    "PLEASE_INPUT_MOBILE_PHONE": "சரியான தொலைபேசி எண்ணைக் குறிப்பிடவும்.",
    "INVALID_PHONE_NUMBER": "சரியான தொலைபேசி எண்ணைக் குறிப்பிடவும்.",
    "PHONE_NUNBER_EXITS": "ஃபோன் எண் ஏற்கனவே மற்றொரு கணக்கில் பயன்பாட்டில் உள்ளது.",
    "USERNAME_EXITS": "இந்த பயனர்பெயர் ஏற்கனவே எங்கள் கணினியில் உள்ளது.",
    "ERROR_INPUT_VALID_USERNAME": "பயனர்பெயரில் குறைந்த எழுத்துக்கள் மற்றும் எண்கள் மட்டுமே இருக்க வேண்டும் மற்றும் 3 முதல் 20 எழுத்துகளுக்கு இடையில் இருக்க வேண்டும்",
    "FIELD_IS_REQUIRED": "இந்த புலம் தேவை.",
    "REFERRAL_CODE_INVALID": "பரிந்துரை குறியீடு தவறானது.",
    "EMAIL_EXITS": "This email already exists. Please enter another one.",
    "INVALID_USERNAME": "சரியான பயனர்பெயரை குறிப்பிடவும்.",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "பயனர் பெயர் 3 மற்றும் 20 எழுத்துகளுக்கு இடையில் இருக்க வேண்டும்.",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "இந்த ஃபோன் சிஸ்டத்தில் உள்ளது. தயவுசெய்து இன்னொன்றை உள்ளிடவும்.",
    "4001": "உரிமத் தட்டு வெளியேறுகிறது",
    "NETWORK_ERROR": "பிழை: பிணையப் பிழை"
  },
  "general": {
    "CONTINUE": "தொடரவும்",
    "STEP_COMPLETE": "{0}/{1} முடிந்தது",
    "STEP_HEADER": "படி {0}",
    "BACK": "மீண்டும்",
    "NEXT": "அடுத்தது",
    "SUBMIT": "சமர்ப்பிக்கவும்",
    "Clear_Selected_Items": "தேர்ந்தெடுக்கப்பட்ட பொருட்களை அழிக்கவும்",
    "no_results": "முடிவுகள் இல்லை",
    "add_new_item": "புதிதாக சேர்க்கவும்"
  },
  "home": {
    "BECOME": "%{name} இயக்கி ஆகுங்கள்.",
    "HEADING": "தொடங்குவதற்கு உங்கள் அடிப்படை தகவலை உள்ளிடவும்",
    "APPLY_NOW": "பதிவு செய்யவும்",
    "INDIVIDUAL_CAR_GOJO": "போக்குவரத்து/டாக்சி - எனக்கு சொந்தமான வாகனம்",
    "INDIVIDUAL_CAR": "போக்குவரத்து - எனக்கு சொந்தமான வாகனம்",
    "COMPANY_CAR": "போக்குவரத்து - நிறுவனத்தின் வாகனம்",
    "DELIVERY_INDIVIDUAL_CAR": "டெலிவரி - எனக்கு சொந்தமான வாகனம்",
    "DELIVERY_COMPANY_CAR": "விநியோகம் - நிறுவனத்தின் வாகனம்",
    "PLACEHOVER_SELECT_PRE_CITY": "நகரம்",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "இயக்கி வகையைத் தேர்ந்தெடுக்கவும்",
    "USERNAME": "பயனர் பெயர்",
    "FIRST_NAME": "முதல் பெயர்",
    "LAST_NAME": "கடைசி பெயர்",
    "EMAIL": "மின்னஞ்சல் (விரும்பினால்)",
    "PHONE_NUMBER": "கைபேசி எண்",
    "REFERRAL_CODE": "பரிந்துரை குறியீடு (விரும்பினால்)",
    "I_AGREE_TO": "பதிவு செய்வதன் மூலம், நான் படித்து ஒப்புக்கொண்டேன்",
    "TERMS_OF_USE": "சேவை விதிமுறைகள்",
    "AND": "மற்றும்",
    "PRIVACY_POLICY": "தனியுரிமைக் கொள்கை",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "%{name} உடன் பதிவு செய்ததற்கு நன்றி",
    "FINISH_3": "தொடங்குவதற்கு இப்போது உள்நுழைக!",
    "FINISH_USE_PASSWORD_1": "ஏறக்குறைய அங்குதான்!",
    "FINISH_USE_PASSWORD_2": "உங்கள் கணக்கைச் செயல்படுத்தவும், வேலைகளைப் பெறவும், இந்தப் படிகளைப் பின்பற்றவும்:",
    "FINISH_USE_PASSWORD_3": "1. உங்கள் பதிவு செய்யப்பட்ட தொலைபேசி எண்ணுடன் உள்நுழையவும்",
    "FINISH_USE_PASSWORD_4": "2. கடவுச்சொல்: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. உங்கள் விண்ணப்பத்தை பூர்த்தி செய்யவும்",
    "OK": "உள்நுழைய",
    "downloadApp": "இப்போது <span style='color: %{color}' >%{name}</span> Driver பயன்பாட்டைப் பதிவிறக்கி, உங்கள் பதிவு செய்யப்பட்ட தொலைபேசி எண்ணைக் கொண்டு உள்நுழையவும்."
  },
  "datePlaceHolder": "MM/DD/YYYY"
};