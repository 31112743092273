export const ur = {
  "name": "Urdu",
  "flagCode": "pk",
  "locale": "ur-PK",
  "project": {
    "name": "ڈرائیور سائن اپ"
  },
  "key": "ur",
  "validations": {
    "PLEASE_SELECT_CITY": "براہ کرم شہر کا انتخاب کریں۔",
    "PLEASE_SELECT_SERVICE": "براہ کرم سروس منتخب کریں۔",
    "PLEASE_SELECT_DRIVER_TYPE": "براہ کرم ڈرائیور کی قسم منتخب کریں۔",
    "PLEASE_INPUT_FIRSTNAME": "براہ کرم اپنا پہلا نام درج کریں۔",
    "PLEASE_INPUT_LASTNAME": "براہ کرم اپنا آخری نام درج کریں۔",
    "PLEASE_INPUT_EMAIL": "براہ کرم اپنا ای میل درج کریں۔",
    "INVALID_EMAIL": "براہ کرم ایک درست ای میل بتائیں۔",
    "PLEASE_INPUT_MOBILE_PHONE": "براہ کرم اپنا فون نمبر درج کریں۔",
    "INVALID_PHONE_NUMBER": "براہ کرم ایک درست فون نمبر بتائیں۔",
    "PHONE_NUNBER_EXITS": "فون نمبر پہلے سے ہی دوسرے اکاؤنٹ کے ساتھ استعمال میں ہے۔",
    "USERNAME_EXITS": "یہ یوزر نیم ہمارے سسٹم پر پہلے سے موجود ہے۔",
    "ERROR_INPUT_VALID_USERNAME": "یوزر نیم میں صرف چھوٹے حروف اور اعداد ہو سکتے ہیں اور یہ 3 سے 20 حروف کے درمیان ہونا چاہیے۔",
    "FIELD_IS_REQUIRED": "اس کو پر کرنا ضروری ہے.",
    "REFERRAL_CODE_INVALID": "ریفرل کوڈ غلط ہے۔",
    "EMAIL_EXITS": "This email already exists. Please enter another one.",
    "INVALID_USERNAME": "براہ کرم ایک درستیوزر نیم  بتائیں.",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "صارف کا نام 3 سے 20 حروف کے درمیان ہونا چاہیے۔",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "یہ فون سسٹم پر موجود ہے۔ براہ کرم ایک اور داخل کریں۔",
    "4001": "The License Plate is exits",
    "NETWORK_ERROR": "خرابی: نیٹ ورک کی خرابی۔"
  },
  "general": {
    "CONTINUE": "جاری رہے",
    "STEP_COMPLETE": "{0}/{1} مکمل",
    "STEP_HEADER": "مرحلہ {0}",
    "BACK": "پیچھے",
    "NEXT": "اگلے",
    "SUBMIT": "جمع کرائیں",
    "Clear_Selected_Items": "منتخب کردہ آئٹمز کو صاف کریں۔",
    "no_results": "کوئی نتیجہ نہیں۔",
    "add_new_item": "نیا شامل کریں"
  },
  "home": {
    "BECOME": "%{name} ڈرائیور بنیں۔",
    "HEADING": "شروع کرنے کے لیے اپنی بنیادی معلومات درج کریں۔",
    "APPLY_NOW": "سائن اپ",
    "INDIVIDUAL_CAR_GOJO": "ٹرانسپورٹ/ٹیکسی - میرے پاس گاڑی ہے۔",
    "INDIVIDUAL_CAR": "ٹرانسپورٹ - میرے پاس گاڑی ہے۔",
    "COMPANY_CAR": "ٹرانسپورٹ - کمپنی کی گاڑی",
    "DELIVERY_INDIVIDUAL_CAR": "ڈیلیوری - میرے پاس گاڑی ہے۔",
    "DELIVERY_COMPANY_CAR": "ڈیلیوری - کمپنی کی گاڑی",
    "PLACEHOVER_SELECT_PRE_CITY": "شہر",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "ڈرائیور کی قسم منتخب کریں۔",
    "USERNAME": "صارف کا نام",
    "FIRST_NAME": "پہلا نام",
    "LAST_NAME": "آخری نام",
    "EMAIL": "ای میل (اختیاری)",
    "PHONE_NUMBER": "موبائل نمبر",
    "REFERRAL_CODE": "ریفرل کوڈ (اختیاری)",
    "I_AGREE_TO": "رجسٹر کر کے، میں نے پڑھ لیا ہے اور اس سے اتفاق کیا ہے۔",
    "TERMS_OF_USE": "سروس کی شرائط",
    "AND": "اور",
    "PRIVACY_POLICY": "رازداری کی پالیسی",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "%{name} کے ساتھ سائن اپ کرنے کا شکریہ!",
    "FINISH_3": "شروع کرنے کے لیے ابھی لاگ ان کریں!",
    "FINISH_USE_PASSWORD_1": "تقریباً پہنچ گیا۔",
    "FINISH_USE_PASSWORD_2": "اپنا اکاؤنٹ چالو کرنے اور جوبز حاصل کرنے کے لیے، براہ کرم ان مراحل پر عمل کریں:",
    "FINISH_USE_PASSWORD_3": "1. اپنے رجسٹرڈ فون نمبر کے ساتھ لاگ ان کریں۔",
    "FINISH_USE_PASSWORD_4": "2. پاس ورڈ: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. اپنی درخواست مکمل کریں۔",
    "OK": "لاگ ان کریں",
    "downloadApp": "ابھی <span style='color: %{color}' >%{name}</span> ڈرائیور ایپ ڈاؤن لوڈ کریں اور اپنے رجسٹرڈ فون نمبر کے ساتھ لاگ ان کریں۔"
  },
  "datePlaceHolder": "MM/DD/YYYY"
};