export const ja = {
  "name": "Japanese",
  "flagCode": "jp",
  "locale": "ja-JP",
  "project": {
    "name": "Driver Signup"
  },
  "key": "ja",
  "validations": {
    "PLEASE_SELECT_CITY": "市区町村を選択してください。",
    "PLEASE_SELECT_SERVICE": "サービスを選択してください。",
    "PLEASE_SELECT_DRIVER_TYPE": "ドライバー種別を選択してください。",
    "PLEASE_INPUT_FIRSTNAME": "あなたの名を入力してください。",
    "PLEASE_INPUT_LASTNAME": "あなたの姓を入力してください。",
    "PLEASE_INPUT_EMAIL": "メールアドレスを入力してください。",
    "INVALID_EMAIL": "有効なメールアドレスを入力してください。",
    "PLEASE_INPUT_MOBILE_PHONE": "電話番号を入力してください。",
    "INVALID_PHONE_NUMBER": "有効な電話番号を入力してください。",
    "PHONE_NUNBER_EXITS": "この電話番号は別のアカウントで使用済みです。",
    "USERNAME_EXITS": "このユーザー名はシステム上に既に登録されています。",
    "ERROR_INPUT_VALID_USERNAME": "ユーザー名には小文字と数字のみ使用可能です。3 ～ 20 文字で入力してください。",
    "FIELD_IS_REQUIRED": "この項目は必須です。",
    "REFERRAL_CODE_INVALID": "紹介コードが無効です。",
    "EMAIL_EXITS": "This email already exists. Please enter another one.",
    "INVALID_USERNAME": "有効なユーザー名を入力してください。",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "ユーザー名は 3 ～ 20 文字で入力してください。",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "この電話番号はシステム上に既に登録されています。別の電話番号を入力してください。",
    "4001": "入力されたプレートナンバーは既に登録されています。",
    "NETWORK_ERROR": "ネットワークエラーです。"
  },
  "general": {
    "CONTINUE": "続ける。",
    "STEP_COMPLETE": "{0}/{1} 完了",
    "STEP_HEADER": "ステップ{0}",
    "BACK": "戻る",
    "NEXT": "次へ",
    "SUBMIT": "送信",
    "Clear_Selected_Items": "選択した項目を消去する。",
    "no_results": "結果がありません。",
    "add_new_item": "新規追加"
  },
  "home": {
    "BECOME": "%{name} のドライバーに登録しましょう。",
    "HEADING": "基本情報を入力して始めましょう。",
    "APPLY_NOW": "新規登録",
    "INDIVIDUAL_CAR_GOJO": "運輸/タクシー - 個人所有車両",
    "INDIVIDUAL_CAR": "運輸 - 個人所有車両",
    "COMPANY_CAR": "運輸 - 法人所有車両",
    "DELIVERY_INDIVIDUAL_CAR": "配達 - 個人所有車両",
    "DELIVERY_COMPANY_CAR": "配達 - 法人所有車両",
    "PLACEHOVER_SELECT_PRE_CITY": "市",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "ドライバー種別を選択してください。",
    "USERNAME": "ユーザー名",
    "FIRST_NAME": "名",
    "LAST_NAME": "姓",
    "EMAIL": "メールアドレス",
    "PHONE_NUMBER": "携帯電話番号",
    "REFERRAL_CODE": "紹介コード（オプション）",
    "I_AGREE_TO": "登録することで、以下を読んで同意したこととみなします。",
    "TERMS_OF_USE": "利用規約",
    "AND": "および",
    "PRIVACY_POLICY": "プライバシーポリシー",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "%{name} にご登録いただきありがとうございます。",
    "FINISH_3": "今すぐログインして始めましょう!",
    "FINISH_USE_PASSWORD_1": "あと少しです！",
    "FINISH_USE_PASSWORD_2": "アカウントを有効にして予約を受付けるには、次の手順に従ってください。",
    "FINISH_USE_PASSWORD_3": "1.登録した電話番号でログイン",
    "FINISH_USE_PASSWORD_4": "2.パスワード: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3.申込みを完了する。",
    "OK": "ログイン",
    "downloadApp": "今すぐ<span style='color: %{color}' >%{name}</span>ドライバー アプリをダウンロードし、登録した電話番号でログインしてください。"
  },
  "datePlaceHolder": "YYYY/MM/DD（西暦年/月/日）"
};