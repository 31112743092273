export const te = {
  "name": "Telugu",
  "flagCode": "in",
  "locale": "te-IN",
  "project": {
    "name": "డ్రైవర్ నమోదు"
  },
  "key": "te",
  "validations": {
    "PLEASE_SELECT_CITY": "ఒక నగరాన్ని ఎంచుకోండి.",
    "PLEASE_SELECT_SERVICE": "సేవను ఎంచుకోండి.",
    "PLEASE_SELECT_DRIVER_TYPE": "డ్రైవర్ రకాన్ని ఎంచుకోండి.",
    "PLEASE_INPUT_FIRSTNAME": "మీ మొదటి పేరును నమోదు చేయండి.",
    "PLEASE_INPUT_LASTNAME": "మీ చివరి పేరును నమోదు చేయండి.",
    "PLEASE_INPUT_EMAIL": "దయచేసి మీ ఇమెయిల్‌ని నమోదు చేయండి.",
    "INVALID_EMAIL": "దయచేసి చెల్లుబాటు అయ్యే ఇమెయిల్ చిరునామాను పేర్కొనండి.",
    "PLEASE_INPUT_MOBILE_PHONE": "చెల్లుబాటు అయ్యే ఫోన్ నంబర్‌ను నమోదు చేయండి.",
    "INVALID_PHONE_NUMBER": "చెల్లుబాటు అయ్యే ఫోన్ నంబర్‌ను నమోదు చేయండి.",
    "PHONE_NUNBER_EXITS": "ఫోన్ నంబర్ ఇప్పటికే మరొక ఖాతాతో వాడుకలో ఉంది.",
    "USERNAME_EXITS": "ఈ వినియోగదారు పేరు ఇప్పటికే మా సిస్టమ్‌లో ఉంది.",
    "ERROR_INPUT_VALID_USERNAME": "వినియోగదారు పేరులో కొన్ని అక్షరాలు మరియు సంఖ్యలు మరియు 3 మరియు 20 అక్షరాల మధ్య ఉండాలి",
    "FIELD_IS_REQUIRED": "ఈ స్థలం అవసరం.",
    "REFERRAL_CODE_INVALID": "రెఫరల్ కోడ్ చెల్లదు.",
    "EMAIL_EXITS": "This email already exists. Please enter another one.",
    "INVALID_USERNAME": "చెల్లుబాటు అయ్యే వినియోగదారు పేరును పేర్కొనండి.",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "వినియోగదారు పేరు తప్పనిసరిగా 3 మరియు 20 అక్షరాల మధ్య ఉండాలి.",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "ఇది ఫోన్ సిస్టమ్‌లో ఉంది. దయచేసి మరొకటి నమోదు చేయండి.",
    "4001": "లైసెన్స్ ప్లేట్ బయటకు వస్తుంది",
    "NETWORK_ERROR": "లోపం: నెట్‌వర్క్ లోపం"
  },
  "general": {
    "CONTINUE": "కొనసాగించు",
    "STEP_COMPLETE": "{0}/{1} పూర్తయింది",
    "STEP_HEADER": "దశ {0}",
    "BACK": "మళ్ళీ",
    "NEXT": "తరువాత",
    "SUBMIT": "సమర్పించండి",
    "Clear_Selected_Items": "ఎంచుకున్న అంశాలను తొలగించండి",
    "no_results": "ఫలితాలు లేవు",
    "add_new_item": "కొత్తదాన్ని జోడించండి"
  },
  "home": {
    "BECOME": "%{name} కి డ్రైవర్‌గా ఉండండి.",
    "HEADING": "ప్రారంభించడానికి మీ ప్రాథమిక సమాచారాన్ని నమోదు చేయండి",
    "APPLY_NOW": "చేరడం",
    "INDIVIDUAL_CAR_GOJO": "రవాణా / టాక్సీ - స్వంత వాహనం",
    "INDIVIDUAL_CAR": "రవాణా - నా స్వంతమైన వాహనాలు",
    "COMPANY_CAR": "రవాణా - కంపెనీ వాహనాలు",
    "DELIVERY_INDIVIDUAL_CAR": "డెలివరీ - నా స్వంత వాహనం",
    "DELIVERY_COMPANY_CAR": "డెలివరీ - కంపెనీ వాహనం",
    "PLACEHOVER_SELECT_PRE_CITY": "నగరం",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "డ్రైవర్ రకాన్ని ఎంచుకోండి",
    "USERNAME": "వినియోగదారు పేరు",
    "FIRST_NAME": "సంతృప్తి చెందారు",
    "LAST_NAME": "ఇంటిపేరు",
    "EMAIL": "ఇ-మెయిల్ (ఐచ్ఛికం)",
    "PHONE_NUMBER": "మొబైల్ నంబర్",
    "REFERRAL_CODE": "రెఫరల్ కోడ్ (ఐచ్ఛికం)",
    "I_AGREE_TO": "నమోదు చేయడం ద్వారా, నేను చదివి అంగీకరించాను",
    "TERMS_OF_USE": "సేవా నిబంధనలు",
    "AND": "మరియు",
    "PRIVACY_POLICY": "గోప్యతా విధానం",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "%{name} . నమోదు చేసుకున్నందుకు ధన్యవాదాలు",
    "FINISH_3": "ప్రారంభించడానికి ఇప్పుడే లాగిన్ చేయండి!",
    "FINISH_USE_PASSWORD_1": "దాదాపు అక్కడ!",
    "FINISH_USE_PASSWORD_2": "మీ ఖాతాను సక్రియం చేయడానికి మరియు ఉద్యోగం పొందడానికి ఈ దశలను అనుసరించండి:",
    "FINISH_USE_PASSWORD_3": "1. మీ రిజిస్టర్డ్ ఫోన్ నంబర్‌తో లాగిన్ చేయండి",
    "FINISH_USE_PASSWORD_4": "2. పాస్‌వర్డ్: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. మీ దరఖాస్తును పూర్తి చేయండి",
    "OK": "లాగిన్ అవ్వడానికి",
    "downloadApp": "<span style='color: %{color}' >%{name}</span> డ్రైవర్ యాప్‌ని ఇప్పుడే డౌన్‌లోడ్ చేసుకోండి మరియు మీ రిజిస్టర్డ్ ఫోన్ నంబర్‌తో లాగిన్ చేయండి."
  },
  "datePlaceHolder": "MM/DD/YYYY"
};