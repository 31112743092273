export const kn = {
  "name": "Kannada",
  "flagCode": "in",
  "locale": "kn-IN",
  "project": {
    "name": "ಚಾಲಕ ನೋಂದಣಿ"
  },
  "key": "kn",
  "validations": {
    "PLEASE_SELECT_CITY": "ನಗರವನ್ನು ಆಯ್ಕೆಮಾಡಿ.",
    "PLEASE_SELECT_SERVICE": "ಸೇವೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ.",
    "PLEASE_SELECT_DRIVER_TYPE": "ಚಾಲಕ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ.",
    "PLEASE_INPUT_FIRSTNAME": "ನಿಮ್ಮ ಮೊದಲ ಹೆಸರನ್ನು ನಮೂದಿಸಿ.",
    "PLEASE_INPUT_LASTNAME": "ನಿಮ್ಮ ಕೊನೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ.",
    "PLEASE_INPUT_EMAIL": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ನಮೂದಿಸಿ.",
    "INVALID_EMAIL": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಇಮೇಲ್ ವಿಳಾಸವನ್ನು ನಿರ್ದಿಷ್ಟಪಡಿಸಿ.",
    "PLEASE_INPUT_MOBILE_PHONE": "ನಿಮ್ಮ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ.",
    "INVALID_PHONE_NUMBER": "ಮಾನ್ಯವಾದ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ.",
    "PHONE_NUNBER_EXITS": "ಫೋನ್ ಸಂಖ್ಯೆಯು ಈಗಾಗಲೇ ಮತ್ತೊಂದು ಖಾತೆಯೊಂದಿಗೆ ಬಳಕೆಯಲ್ಲಿದೆ.",
    "USERNAME_EXITS": "ಈ ಬಳಕೆದಾರಹೆಸರು ನಮ್ಮ ಸಿಸ್ಟಂನಲ್ಲಿ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ.",
    "ERROR_INPUT_VALID_USERNAME": "ಬಳಕೆದಾರಹೆಸರು ಕೆಲವೇ ಅಕ್ಷರಗಳು ಮತ್ತು ಸಂಖ್ಯೆಗಳನ್ನು ಹೊಂದಿರಬೇಕು ಮತ್ತು 3 ರಿಂದ 20 ಅಕ್ಷರಗಳ ನಡುವೆ ಇರಬೇಕು",
    "FIELD_IS_REQUIRED": "ಈ ಜಾಗ ಬೇಕಾಗಿದೆ.",
    "REFERRAL_CODE_INVALID": "ರೆಫರಲ್ ಕೋಡ್ ಅಮಾನ್ಯವಾಗಿದೆ.",
    "EMAIL_EXITS": "This email already exists. Please enter another one.",
    "INVALID_USERNAME": "ಮಾನ್ಯವಾದ ಬಳಕೆದಾರ ಹೆಸರನ್ನು ಸೂಚಿಸಿ.",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "ಬಳಕೆದಾರಹೆಸರು 3 ರಿಂದ 20 ಅಕ್ಷರಗಳ ನಡುವೆ ಇರಬೇಕು.",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "ಇದು ಫೋನ್ ವ್ಯವಸ್ಥೆಯಲ್ಲಿದೆ. ದಯವಿಟ್ಟು ಇನ್ನೊಂದನ್ನು ನಮೂದಿಸಿ.",
    "4001": "ಪರವಾನಗಿ ಫಲಕ ಹೊರಬರುತ್ತದೆ",
    "NETWORK_ERROR": "ದೋಷ: ನೆಟ್‌ವರ್ಕ್ ದೋಷ"
  },
  "general": {
    "CONTINUE": "ಮುಂದುವರಿಸಿ",
    "STEP_COMPLETE": "{0}/{1} ಮುಗಿದಿದೆ",
    "STEP_HEADER": "ಹಂತ {0}",
    "BACK": "ಮತ್ತೆ",
    "NEXT": "ಮುಂದೆ",
    "SUBMIT": "ಸಲ್ಲಿಸು",
    "Clear_Selected_Items": "ಆಯ್ಕೆಮಾಡಿದ ಐಟಂಗಳನ್ನು ಅಳಿಸಿ",
    "no_results": "ಯಾವುದೇ ಫಲಿತಾಂಶಗಳಿಲ್ಲ",
    "add_new_item": "ಹೊಸದನ್ನು ಸೇರಿಸಿ"
  },
  "home": {
    "BECOME": "%{name} ಚಾಲಕರಾಗಿರಿ.",
    "HEADING": "ಪ್ರಾರಂಭಿಸಲು ನಿಮ್ಮ ಮೂಲ ಮಾಹಿತಿಯನ್ನು ನಮೂದಿಸಿ",
    "APPLY_NOW": "ಸೈನ್ ಅಪ್ ಮಾಡಿ",
    "INDIVIDUAL_CAR_GOJO": "ಸಾರಿಗೆ / ಟ್ಯಾಕ್ಸಿ - ಸ್ವಂತ ವಾಹನ",
    "INDIVIDUAL_CAR": "ಸಾರಿಗೆ - ನನ್ನ ಒಡೆತನದ ವಾಹನಗಳು",
    "COMPANY_CAR": "ಸಾರಿಗೆ - ಕಂಪನಿಯ ವಾಹನಗಳು",
    "DELIVERY_INDIVIDUAL_CAR": "ವಿತರಣೆ - ನನ್ನ ಮಾಲೀಕತ್ವದ ವಾಹನ",
    "DELIVERY_COMPANY_CAR": "ವಿತರಣೆ - ಕಂಪನಿಯ ವಾಹನ",
    "PLACEHOVER_SELECT_PRE_CITY": "ನಗರ",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "ಚಾಲಕ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    "USERNAME": "ಬಳಕೆದಾರ ಹೆಸರು",
    "FIRST_NAME": "ತೃಪ್ತಿ",
    "LAST_NAME": "ಉಪನಾಮ",
    "EMAIL": "ಇ-ಮೇಲ್ (ಐಚ್ಛಿಕ)",
    "PHONE_NUMBER": "ಮೊಬೈಲ್ ನಂಬರ",
    "REFERRAL_CODE": "ರೆಫರಲ್ ಕೋಡ್ (ಐಚ್ಛಿಕ)",
    "I_AGREE_TO": "ನೋಂದಾಯಿಸುವ ಮೂಲಕ, ನಾನು ಓದಿದ್ದೇನೆ ಮತ್ತು ಒಪ್ಪುತ್ತೇನೆ",
    "TERMS_OF_USE": "ಸೇವಾ ನಿಯಮಗಳು",
    "AND": "ಮತ್ತು",
    "PRIVACY_POLICY": "ಗೌಪ್ಯತಾ ನೀತಿ",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "%{name} ಜೊತೆಗೆ ನೋಂದಾಯಿಸಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು!",
    "FINISH_3": "ಪ್ರಾರಂಭಿಸಲು ಈಗ ಲಾಗಿನ್ ಮಾಡಿ!",
    "FINISH_USE_PASSWORD_1": "ಬಹುತೇಕ ಅಲ್ಲಿ!",
    "FINISH_USE_PASSWORD_2": "ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಲು ಮತ್ತು ಉದ್ಯೋಗವನ್ನು ಪಡೆಯಲು ಈ ಹಂತಗಳನ್ನು ಅನುಸರಿಸಿ:",
    "FINISH_USE_PASSWORD_3": "1. ನಿಮ್ಮ ನೋಂದಾಯಿತ ಫೋನ್ ಸಂಖ್ಯೆಯೊಂದಿಗೆ ಲಾಗಿನ್ ಮಾಡಿ",
    "FINISH_USE_PASSWORD_4": "2. ಪಾಸ್‌ವರ್ಡ್: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ",
    "OK": "ಲಾಗ್ ಇನ್ ಮಾಡಲು",
    "downloadApp": "<span style='color: %{color}' >%{name}</span> ಡ್ರೈವರ್ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಇದೀಗ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ ಮತ್ತು ನಿಮ್ಮ ನೋಂದಾಯಿತ ಫೋನ್ ಸಂಖ್ಯೆಯೊಂದಿಗೆ ಲಾಗಿನ್ ಮಾಡಿ."
  },
  "datePlaceHolder": "MM/DD/YYYY"
};