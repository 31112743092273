export const tl = {
  "name": "Tagalog",
  "flagCode": "ph",
  "locale": "tl-PH",
  "project": {
    "name": "Pag-signUp sa Driver"
  },
  "key": "tl",
  "validations": {
    "PLEASE_SELECT_CITY": "Mangyaring pumili ng lungsod.",
    "PLEASE_SELECT_SERVICE": "Mangyaring pumili ng serbisyo.",
    "PLEASE_SELECT_DRIVER_TYPE": "Mangyaring piliin ang uri ng driver.",
    "PLEASE_INPUT_FIRSTNAME": "Pakilagay ang iyong pangalan.",
    "PLEASE_INPUT_LASTNAME": "Pakilagay ang iyong apelyido.",
    "PLEASE_INPUT_EMAIL": "Pakilagay ang iyong email..",
    "INVALID_EMAIL": "Mangyaring tumukoy ng wastong email.",
    "PLEASE_INPUT_MOBILE_PHONE": "Pakibigay na lng po ng phone number niyo.",
    "INVALID_PHONE_NUMBER": "Mangyaring tumukoy ng wastong numero ng telepono.",
    "PHONE_NUNBER_EXITS": "Ginagamit na ang numero ng telepono sa ibang account.",
    "USERNAME_EXITS": "Umiiral na ang username na ito sa aming system.",
    "ERROR_INPUT_VALID_USERNAME": "Ang username ay maaari lamang maglaman ng mas mababang mga titik at numero at dapat ay nasa pagitan ng 3 at 20 character ang haba",
    "FIELD_IS_REQUIRED": "Kinakailangan ang field na ito.",
    "REFERRAL_CODE_INVALID": "Di-wasto ang referral code.",
    "EMAIL_EXITS": "This email already exists. Please enter another one.",
    "INVALID_USERNAME": "Mangyaring tumukoy ng wastong username.",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "Ang username ay dapat nasa pagitan ng 3 at 20 character.",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "Ang teleponong ito ay umiiral na sa system. Mangyaring maglagay ng isa pa.",
    "4001": "The License Plate is exits",
    "NETWORK_ERROR": "Error: Error sa Network"
  },
  "general": {
    "CONTINUE": "Magpatuloy",
    "STEP_COMPLETE": "{0}/{1} Kumpleto",
    "STEP_HEADER": "Hakbang {0}",
    "BACK": "Bumalik",
    "NEXT": "Susunod",
    "SUBMIT": "Ipasa",
    "Clear_Selected_Items": "I-clear ang mga napiling item",
    "no_results": "Walang resulta",
    "add_new_item": "Magdagdag ng bago"
  },
  "home": {
    "BECOME": "Maging isang %{name} Driver.",
    "HEADING": "Ilagay ang iyong pangunahing impormasyon upang makapagsimula",
    "APPLY_NOW": "Mag-sign up",
    "INDIVIDUAL_CAR_GOJO": "Transport/Taxi - May sarili akong sasakyan",
    "INDIVIDUAL_CAR": "Transport - Ako ay may sariling sasakyan",
    "COMPANY_CAR": "Transportasyon - Sasakyan ng kumpanya",
    "DELIVERY_INDIVIDUAL_CAR": "Paghahatid - May sarili akong sasakyan",
    "DELIVERY_COMPANY_CAR": "Paghahatid - Sasakyan ng kumpanya",
    "PLACEHOVER_SELECT_PRE_CITY": "Lungsod",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "Piliin ang uri ng driver",
    "USERNAME": "Username",
    "FIRST_NAME": "Pangalan",
    "LAST_NAME": "Huling pangalan",
    "EMAIL": "Email (opsyonal)",
    "PHONE_NUMBER": "Mobile Number",
    "REFERRAL_CODE": "Referral code (opsyonal)",
    "I_AGREE_TO": "Sa pamamagitan ng pagrehistro, nabasa ko at sumang-ayon sa",
    "TERMS_OF_USE": "Mga Tuntunin ng Serbisyo",
    "AND": "at",
    "PRIVACY_POLICY": "Patakaran sa Privacy",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "Salamat sa pag-sign up sa %{name}!",
    "FINISH_3": "Mag-login ngayon para makapagsimula!",
    "FINISH_USE_PASSWORD_1": "Malapit na!",
    "FINISH_USE_PASSWORD_2": "Upang i-activate ang iyong account at makatanggap ng mga trabaho, mangyaring sundin ang mga hakbang na ito:",
    "FINISH_USE_PASSWORD_3": "1. Mag-login gamit ang iyong rehistradong numero ng telepono",
    "FINISH_USE_PASSWORD_4": "2. Password: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. Kumpletuhin ang iyong aplikasyon",
    "OK": "Mag log in",
    "downloadApp": "I-download ang <span style='color: %{color}' >%{name}</span> Driver app ngayon at mag-log in gamit ang iyong nakarehistrong numero ng telepono."
  },
  "datePlaceHolder": "MM/DD/YYYY"
};