export const nb = {
  "name": "Norwegian",
  "flagCode": "no",
  "locale": "nb-NO",
  "project": {
    "name": "Driver Signup"
  },
  "key": "nb",
  "validations": {
    "PLEASE_SELECT_CITY": "Please select city.",
    "PLEASE_SELECT_SERVICE": "Please select service.",
    "PLEASE_SELECT_DRIVER_TYPE": "Please select driver type.",
    "PLEASE_INPUT_FIRSTNAME": "Please enter your first name.",
    "PLEASE_INPUT_LASTNAME": "Please enter your last name.",
    "PLEASE_INPUT_EMAIL": "Please enter your email.",
    "INVALID_EMAIL": "Vennligst oppgi en gyldig e-post.",
    "PLEASE_INPUT_MOBILE_PHONE": "Please enter your phone number.",
    "INVALID_PHONE_NUMBER": "Please specify a valid phone number.",
    "PHONE_NUNBER_EXITS": "Telefonnummer allerede i bruk med en annen konto.",
    "USERNAME_EXITS": "This username already exists on our system.",
    "ERROR_INPUT_VALID_USERNAME": "Username may only contain lower letters & numbers and must be between 3 and 20 characters long",
    "FIELD_IS_REQUIRED": "Dette feltet er obligatorisk.",
    "REFERRAL_CODE_INVALID": "Referral code is invalid.",
    "EMAIL_EXITS": "This email already exists. Please enter another one.",
    "INVALID_USERNAME": "Please specify a valid username.",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "Username must be between 3 and 20 characters.",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "This phone has been existing on the system. Please input another one.",
    "4001": "The License Plate is exits",
    "NETWORK_ERROR": "Error: Network Error"
  },
  "general": {
    "CONTINUE": "Continue",
    "STEP_COMPLETE": "{0}/{1} Complete",
    "STEP_HEADER": "Step {0}",
    "BACK": "Back",
    "NEXT": "Next",
    "SUBMIT": "Submit",
    "Clear_Selected_Items": "Clear selected items",
    "no_results": "No results",
    "add_new_item": "Add New"
  },
  "home": {
    "BECOME": "Become a %{name} Driver.",
    "HEADING": "Enter your basic information to get started",
    "APPLY_NOW": "Registrer deg",
    "INDIVIDUAL_CAR_GOJO": "Transport/Taxi - I own vehicle",
    "INDIVIDUAL_CAR": "Transport - Jeg eier egen bil",
    "COMPANY_CAR": "Transport - Firmabil",
    "DELIVERY_INDIVIDUAL_CAR": "Levering - Jeg eier egen bil",
    "DELIVERY_COMPANY_CAR": "Levering - Firmabil",
    "PLACEHOVER_SELECT_PRE_CITY": "By",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "Velg sjåførtype",
    "USERNAME": "Brukernavn",
    "FIRST_NAME": "Fornavn",
    "LAST_NAME": "Etternavn",
    "EMAIL": "E-post (valgfritt)",
    "PHONE_NUMBER": "Mobilnummer",
    "REFERRAL_CODE": "Referansekode (valgfritt)",
    "I_AGREE_TO": "Ved å registrere meg, har jeg les og akseptert",
    "TERMS_OF_USE": "Vilkår for tjenesten",
    "AND": "og",
    "PRIVACY_POLICY": "personvern",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "Takk for at du registrerer deg hos %{name}!",
    "FINISH_3": "Logg inn nå for å komme i gang!",
    "FINISH_USE_PASSWORD_1": "Almost there!",
    "FINISH_USE_PASSWORD_2": "To activate your account and receive jobs, please follow these steps:",
    "FINISH_USE_PASSWORD_3": "1. Login with your registered phone number",
    "FINISH_USE_PASSWORD_4": "2. Password: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. Complete your application",
    "OK": "Logg inn",
    "downloadApp": "Last ned <span style='color: %{color}' >%{name}</span> Sjåfør app nå og logg inn med ditt registrerte telefonnummer."
  },
  "datePlaceHolder": "MM/DD/YYYY"
};