export const kk = {
  "name": "Kazakh",
  "flagCode": "kz",
  "locale": "kk-KZ",
  "project": {
    "name": "Жүргізушіні тіркеу"
  },
  "key": "kk",
  "validations": {
    "PLEASE_SELECT_CITY": "Өтініш, қаланы таңдаңыз.",
    "PLEASE_SELECT_SERVICE": "Өтініш, қызмет түрін таңдаңыз.",
    "PLEASE_SELECT_DRIVER_TYPE": "Өтініш, жүргізуші түрін таңдаңыз.",
    "PLEASE_INPUT_FIRSTNAME": "Өтініш, атыңызды енгізіңіз.",
    "PLEASE_INPUT_LASTNAME": "Өтініш, тегіңізді енгізіңіз.",
    "PLEASE_INPUT_EMAIL": "Өтініш, электрондық поштаңызды енгізіңіз.",
    "INVALID_EMAIL": "Өтініш, қолданыстағы электрондық поштаңызды көрсетіңіз.",
    "PLEASE_INPUT_MOBILE_PHONE": "Өтініш, телефон нөміріңізді енгізіңіз.",
    "INVALID_PHONE_NUMBER": "Өтініш, қолданыстағы телефон нөмірін көрсетіңіз.",
    "PHONE_NUNBER_EXITS": "Телефон нөмірі басқа аккаунтпен бұрыннан қолданыста.",
    "USERNAME_EXITS": "Бұл пайдаланушы біздің жүйеде бұрыннан тіркелген.",
    "ERROR_INPUT_VALID_USERNAME": "Пайдаланушы аты тек кіші әріптер мен сандарды \nқамтуы тиіс және 3-тен 20-ға дейінгі ұзындықта болуы қажет.",
    "FIELD_IS_REQUIRED": "Бұл орын міндетті түрде толтырылуы тиіс.",
    "REFERRAL_CODE_INVALID": "Рефералды сілтеме коды жарамсыз.",
    "EMAIL_EXITS": "This email already exists. Please enter another one.",
    "INVALID_USERNAME": "Қолданушының нақты атын көрсетіңіз.",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "Пайдаланушы аты 3-тен 20-ға дейінгі ұзындықта болуы қажет.",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "Бұл нөмір жүйеде тіркелген. Өтініш, басқа нөмір енгізіңіз.",
    "4001": "Бұл нөмір бар",
    "NETWORK_ERROR": "Қате: желі қатесі"
  },
  "general": {
    "CONTINUE": "Жалғастыру",
    "STEP_COMPLETE": "Аяқтау {0}/{1} ",
    "STEP_HEADER": "Қадам {0}",
    "BACK": "Артқа",
    "NEXT": "Алға",
    "SUBMIT": "Жіберу",
    "Clear_Selected_Items": "Таңдалған элементтерді жою",
    "no_results": "Нәтиже табылмады",
    "add_new_item": "Жаңасын қосу"
  },
  "home": {
    "BECOME": "%{name} жүргізушісі болыңыз.",
    "HEADING": "Жұмысты бастау үшін негізгі ақпаратты енгізіңіз",
    "APPLY_NOW": "Тіркелу",
    "INDIVIDUAL_CAR_GOJO": "Көлік/Такси - Менің жеке көлігім бар",
    "INDIVIDUAL_CAR": "Көлік - Менің жеке көлігім бар",
    "COMPANY_CAR": "Көлік-  Менің қызметтік көлігім бар",
    "DELIVERY_INDIVIDUAL_CAR": "Жеткізу - Менің жеке көлігім бар",
    "DELIVERY_COMPANY_CAR": "Жеткізу - Менің қызметтік көлігім бар",
    "PLACEHOVER_SELECT_PRE_CITY": "Қала",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "Жүргізуші түрін таңдаңыз",
    "USERNAME": "Пайдаланушының аты",
    "FIRST_NAME": "Аты",
    "LAST_NAME": "Тегі",
    "EMAIL": "Эл. пошта (міндетті емес)",
    "PHONE_NUMBER": "Ұялы телефон нөмірі",
    "REFERRAL_CODE": "Рефералды сілтеме коды (міндетті емес)",
    "I_AGREE_TO": "Тіркелу арқылы Мен .......келісемін",
    "TERMS_OF_USE": "Қызмет көрсету шарттары",
    "AND": "сондай-ақ",
    "PRIVACY_POLICY": "Құпиялылық саясаты",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "%{name} - да тіркелгеніңіз үшін рахмет!",
    "FINISH_3": "Жұмысты бастау үшін жүйеге кіріңіз!",
    "FINISH_USE_PASSWORD_1": "Мен келіп қалдым!",
    "FINISH_USE_PASSWORD_2": "Аккаунтты іске қосу және тапсырыс алу үшін келесі әрекеттерді орындаңыз:\n",
    "FINISH_USE_PASSWORD_3": "1. Тіелефон нөміріңізбен жүйеге кіріңіз",
    "FINISH_USE_PASSWORD_4": "2. Құпия сөз: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. Өтінімді толтырыңыз",
    "OK": "Кіру",
    "downloadApp": "Дәл қазір жүргізушілер үшін <span style='color: %{color}' >%{name}</span> қолданбасын жүктеңіз және телефон нөміріңізді пайдаланып жүйеге кіріңіз."
  },
  "datePlaceHolder": "MM/DD/YYY"
};