export const urRO = {
  "name": "Roman Urdu",
  "flagCode": "pk",
  "locale": "ur-PK",
  "project": {
    "name": "Driver SignUp"
  },
  "key": "urRO",
  "validations": {
    "PLEASE_SELECT_CITY": "Barah e karam shehr ka intekhab karein",
    "PLEASE_SELECT_SERVICE": "Barah e karam service muntakhib karein",
    "PLEASE_SELECT_DRIVER_TYPE": "Barah e karam driver ki qism muntakhib karein",
    "PLEASE_INPUT_FIRSTNAME": "Barah e karam apna pehla naam darj karein",
    "PLEASE_INPUT_LASTNAME": "Barah e karam apna akhri naam darj karein",
    "PLEASE_INPUT_EMAIL": "Barah e karam apna e-mail darj karein ",
    "INVALID_EMAIL": "Barah e karam aik durust e-mail batayein",
    "PLEASE_INPUT_MOBILE_PHONE": "Barah e karam apna phone number darj karein ",
    "INVALID_PHONE_NUMBER": "Barah e karam aik durust phone number batayein",
    "PHONE_NUNBER_EXITS": "Phone number pehle se hi dosray account ke sath istemal mei hai ",
    "USERNAME_EXITS": "Yea username hamare system par pehle se mojoud hai ",
    "ERROR_INPUT_VALID_USERNAME": "Username mei sirf chotay huroof aur aadad hosaktay hain aur yea 3 se 20 huroof ke darmiyan hona chahye",
    "FIELD_IS_REQUIRED": "isko pur karna zaruri hai ",
    "REFERRAL_CODE_INVALID": "Referral code ghalat hai ",
    "EMAIL_EXITS": "This email already exists. Please enter another one.",
    "INVALID_USERNAME": "Barah e karam aik durust usename batayein",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "Sarif ka naam 3 se 20 huroof ke darmiyan hona chahye",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "Ye phone system per mojoud hai. Barah e karam aik or dakhil karein",
    "4001": "The License Plate is exits",
    "NETWORK_ERROR": "Kharabi: network ki kharabi"
  },
  "general": {
    "CONTINUE": "Jaari hai ",
    "STEP_COMPLETE": "{0}/{1} Mukammal",
    "STEP_HEADER": "Marhala {0}",
    "BACK": "Pechay",
    "NEXT": "Agla ",
    "SUBMIT": "Jama karayein",
    "Clear_Selected_Items": "Muntakhib karda items ko saaf karein ",
    "no_results": "Koi nateejah nahi ",
    "add_new_item": "Naya shamil karein "
  },
  "home": {
    "BECOME": "%{name} Driver banein",
    "HEADING": "Shru karne ke liye apni buniyadi maloomat darj karein ",
    "APPLY_NOW": "Sign up ",
    "INDIVIDUAL_CAR_GOJO": "Transport/Taxi - mere pass gari hai ",
    "INDIVIDUAL_CAR": "Transport - Mere pass gari hai ",
    "COMPANY_CAR": "Transport - Company ki gari ",
    "DELIVERY_INDIVIDUAL_CAR": "Delivery - Mere pass gari hai ",
    "DELIVERY_COMPANY_CAR": "Delivery - Company ki gari ",
    "PLACEHOVER_SELECT_PRE_CITY": "Shehr",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "Driver ki qism muntakhib karein ",
    "USERNAME": "Sarif ka naam ",
    "FIRST_NAME": "Pehla naam ",
    "LAST_NAME": "Aakhri naam ",
    "EMAIL": "E-mail (Ikhtiyari)",
    "PHONE_NUMBER": "Mobile number ",
    "REFERRAL_CODE": "Referral code (Ikhtiyari)",
    "I_AGREE_TO": "Registration kar ke, mein ne parh liya hai aur is se ittefaq kiya hai ",
    "TERMS_OF_USE": "Service ki sharait",
    "AND": "Aur",
    "PRIVACY_POLICY": "Razdari ki policy ",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "%{name} ke sath sign up karne ka shukriya!",
    "FINISH_3": "Shru karne ke liye abhi log-in karein!",
    "FINISH_USE_PASSWORD_1": "Taqreeban pohnch gaya",
    "FINISH_USE_PASSWORD_2": "Apna account chalo karne aur jobs hasil karne ke liye, barah e karam in marahil per amal karein ",
    "FINISH_USE_PASSWORD_3": "1. Apne registered phone number ke sath log in karein ",
    "FINISH_USE_PASSWORD_4": "2. Password: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. Apni darkhuwast mukmmal karein ",
    "OK": "Log in karein ",
    "downloadApp": " <span style='color: %{color}' >%{name}</span> Driver app download karein aur apne registered phone number ke sath log in karein "
  },
  "datePlaceHolder": "MM/DD/YYYY"
};