export const ro = {
  "name": "Romanian",
  "flagCode": "ro",
  "locale": "ro-RO",
  "project": {
    "name": "Înregistrare șofer "
  },
  "key": "ro",
  "validations": {
    "PLEASE_SELECT_CITY": "Selectează orașul ",
    "PLEASE_SELECT_SERVICE": "Selectează serviciul",
    "PLEASE_SELECT_DRIVER_TYPE": "Selectează tipul de sofer",
    "PLEASE_INPUT_FIRSTNAME": "Introduceți prenumele ",
    "PLEASE_INPUT_LASTNAME": "Vă rugăm să introduceți numele de familie.",
    "PLEASE_INPUT_EMAIL": "Va rugam sa introduceți mailul dvs.",
    "INVALID_EMAIL": "Va rog introduceți un e-mail valid",
    "PLEASE_INPUT_MOBILE_PHONE": "Va rugam să introduceți numărul dvs. de telefon",
    "INVALID_PHONE_NUMBER": "Va rugam introduceți un număr de telefon valid",
    "PHONE_NUNBER_EXITS": "Număr de telefon deja utilizat",
    "USERNAME_EXITS": "Acest nume de utilizator există deja pe sistemul nostru.",
    "ERROR_INPUT_VALID_USERNAME": "Numele de utilizator poate conține numai litere și cifre mici și trebuie să aibă între 3 și 20 de caractere",
    "FIELD_IS_REQUIRED": "Acest câmp este obligatoriu.",
    "REFERRAL_CODE_INVALID": "Codul de recomandare este invalid",
    "EMAIL_EXITS": "This email already exists. Please enter another one.",
    "INVALID_USERNAME": "Vă rugăm să specificați un nume de utilizator valid.",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "Numele de utilizator trebuie să aibă între 3 și 20 de caractere",
    "PHONE_NUNBER_EXITS_IN_GROUP": "Număr de telefon deja utilizat"
  },
  "errors": {
    "3034": "Acest telefon  exista in sistem. Vă rugăm să introduceți altul.",
    "4001": "Nr de înmatriculare deja existent",
    "NETWORK_ERROR": "Eroare: Eroare rețea "
  },
  "general": {
    "CONTINUE": "Continua",
    "STEP_COMPLETE": "{0}/{1} Finalizat",
    "STEP_HEADER": "Pasul {0}",
    "BACK": "Inapoi",
    "NEXT": "Următorul ",
    "SUBMIT": "Trimite",
    "Clear_Selected_Items": "Șterge elementele selectate",
    "no_results": "Nici un rezultat ",
    "add_new_item": "Adaugă "
  },
  "home": {
    "BECOME": "Deveniți un %{name} Sofer.",
    "HEADING": "Introduceți informațiile de bază pentru a începe",
    "APPLY_NOW": "Înregistrare ",
    "INDIVIDUAL_CAR_GOJO": "Transport/Taxi - Dețin autovehicul ",
    "INDIVIDUAL_CAR": "Transport - Dețin vehicul",
    "COMPANY_CAR": "Transport - Companie vehicule",
    "DELIVERY_INDIVIDUAL_CAR": "Livrări - Dețin mașină ",
    "DELIVERY_COMPANY_CAR": "Livrări - Companie vehicule",
    "PLACEHOVER_SELECT_PRE_CITY": "Oras",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "Selectează tipul de șofer ",
    "USERNAME": "Nume de utilizator ",
    "FIRST_NAME": "Prenume",
    "LAST_NAME": "Nume de familie ",
    "EMAIL": "Email (optional)",
    "PHONE_NUMBER": "Număr de telefon",
    "REFERRAL_CODE": "Cod de recomandare (optional)",
    "I_AGREE_TO": "Prin înregistrare, am citit și am acceptat",
    "TERMS_OF_USE": "Termenii serviciului",
    "AND": "și ",
    "PRIVACY_POLICY": "Politica de Confidențialitate",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "Vă mulțumim că v-ați înscris cu %{name}!",
    "FINISH_3": "Conectați-vă acum pentru a începe!",
    "FINISH_USE_PASSWORD_1": "Aproape gata!",
    "FINISH_USE_PASSWORD_2": "Pentru a vă activa contul și a primi curse, urmați acești pași:",
    "FINISH_USE_PASSWORD_3": "1. Conectați-vă cu numărul dvs. de telefon înregistrat",
    "FINISH_USE_PASSWORD_4": "2. Parola: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. Completați cererea",
    "OK": "Autentificare",
    "downloadApp": "Descărcați acum <span style='color: %{color}'>%{name}</span>Șofer app și conectați-vă cu numărul dvs. de telefon înregistrat"
  },
  "datePlaceHolder": "LL/ZZ/AAAA"
};